import React, { useEffect } from "react";
import { Auth } from "@aws-amplify/auth";
import { Page } from "@/components/Page";

export default function ForbiddenPage() {
  useEffect(() => {
    Auth.signOut();
  }, []);

  return (
    <Page title="403: Forbidden">
      <p>You do not have authorization to view this page.</p>
    </Page>
  );
}
